import {
  AutoComplete,
  Button,
  Dropdown,
  Form,
  Input,
  InputGroup,
  Loader,
  Modal,
  Stack,
  Toggle,
} from "rsuite";
import "./_host-settings.scss";
import TrashIcon from "@rsuite/icons/Trash";
import { useEffect, useState } from "react";
import { DomainTarget, getDomainTargetByIp, IDomain } from "@bprotsyk/aso-core";
import { v4 as uuidv4 } from "uuid";
import FileUploadIcon from "@rsuite/icons/FileUpload";

import { error } from "console";
import { set } from "react-hook-form";
import { PanelService } from "network/panel/network";

const recordsValues = ["TXT", "MX"];


interface IProps {
  editingDomainData: IDomain | null;
  onEditingModal: () => void;
  onStaticModal: () => void;
  load: boolean;
  onDomainDataUpdate:(domain:Partial<IDomain>)=>void;
  
}
interface IP {
  IP: string;
  target: DomainTarget | string;
}

export default function EditingRecordsModal({
  editingDomainData,
  onEditingModal,
  onStaticModal,
  load,
  onDomainDataUpdate
}: IProps) {
  const [domainData, setDomainData] = useState<IDomain | null>(
    editingDomainData
  );
  const [Records, setRecords] = useState(new Map());
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [addTxtBtn, setAddTxtBtn] = useState<boolean>(false);
  const [addMxBtn, setAddMxBtn] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteKey, setDeleteKey] = useState<string>();
  const [ipErr, setIpErr] = useState(false);
  const [ip, setIP] = useState<IP>({
    IP: editingDomainData?.ip || "",
    target: getDomainTargetByIp(editingDomainData?.ip|| "") || 'other',
  });
    const [selectedOption, setSelectedOption] = useState(ip.target);

  const calculateRowsForText = (text: string) => {
    const normalizedText = text.replace(/\s+/g, " ").trim();
    if (!normalizedText) {
      return 1;
    }
    const charsPerLine = 30;
    const lines = normalizedText.split("\n").reduce((acc, line) => {
      return acc + Math.ceil(line.length / charsPerLine);
    }, 0);
    // Повертаємо кількість рядків або мінімум 1
    return Math.max(lines, 1);
  };

  const handleTextareaChange = (recordKey: string, event: any) => {
    const inputValue = event.target.value;

    setRecords((prevRecords) => {
      const currentRecord = prevRecords.get(recordKey);
      if (!currentRecord) return prevRecords;

      const updatedRecords = new Map(prevRecords);

      updatedRecords.set(recordKey, {
        ...currentRecord,
        value: inputValue, // Оновлюємо тільки значення
      });

      return updatedRecords;
    });
    checkForChanges();
  };

  const handleTextareaKeyDown = (recordKey: string, event: any) => {
    if (event.key === "Enter") {
      setRecords((prevRecords) => {
        const currentRecord = prevRecords.get(recordKey);
        if (!currentRecord) return prevRecords;

        const updatedRecords = new Map(prevRecords);

        updatedRecords.set(recordKey, {
          ...currentRecord,
          rows: currentRecord.rows + 1, // Збільшуємо тільки рядки
        });

        return updatedRecords;
      });
      checkForChanges();
    }
  };
  console.log(ip)



  useEffect(() => {
    const newRecords = new Map();

    // Обробка txtRecords (масив)
    editingDomainData?.txtRecords?.forEach((record) => {
      const rows = calculateRowsForText(record);
      newRecords.set(uuidv4(), {
        rows,
        deleted: false,
        value: record,
        type: "TXT",
      });
    });

    // Обробка mxRecord (рядок)
    if (editingDomainData?.mxRecord) {
      const mxRecord = editingDomainData.mxRecord;
      const rows = calculateRowsForText(mxRecord);
      console.log(rows);
      newRecords.set(uuidv4(), {
        rows,
        deleted: false,
        value: mxRecord,
        type: "MX",
      });
    }

    // Перевірка, чи є хоча б один запис
    if (newRecords.size > 0) {
      setAddTxtBtn(true);
      setAddMxBtn(true);
    } else {
      setAddTxtBtn(false);
      setAddMxBtn(false);
    }

    setRecords(newRecords);
  }, [editingDomainData]);

  const ipPattern =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  useEffect(() => {
   setTimeout(()=>{
    setIsInitialized(true);
   },1000)
  }, [Records, ip, isInitialized]);

  const checkForChanges = () => {
    if (!isInitialized) {
      return; // Якщо компонент тільки ініціалізується, ми не перевіряємо зміни
    }

    const hasIpChanged = ip.IP !== editingDomainData?.ip;
    const originalTxtRecordsSet = new Set(editingDomainData?.txtRecords || []);

    const hasTxtRecordsChanged = Array.from(Records.values()).some(
      (currentRecord) => {
        return (
          currentRecord.deleted !== false || // Якщо запис позначено як видалений
          !originalTxtRecordsSet.has(currentRecord.value) || // Якщо значення нове (відсутнє в оригінальних даних)
          (currentRecord.value &&
            !originalTxtRecordsSet.has(currentRecord.value)) // Якщо значення змінилося
        );
      }
    );

    setIsFormChanged(hasIpChanged || hasTxtRecordsChanged);
  };

  const handleIpChange = (handleIP: string) => {
    if (ip.target === "other") {
      setIP({
        IP: handleIP,
        target: getDomainTargetByIp(handleIP),
      });
      setSelectedOption(getDomainTargetByIp(handleIP) || 'other')
      console.log(getDomainTargetByIp(handleIP))
      if (!ip.target || (handleIP.length > 0 && !ipPattern.test(handleIP))) {
        setIpErr(true);
      } else {
        setIpErr(false);
      }
    }
  };
  useEffect(() => {
    if(isInitialized){
      if (!ipPattern.test(ip.IP)) {
        setIpErr(!ipErr);
      } else {
        setIpErr(!ipErr);
      }
    }
  }, [ip]);

  useEffect(() => {
    if (ip.IP && ip.target) {
      setIpErr(false);
    }
  }, [ip]);


  useEffect(() => {
    if (isInitialized) {
      checkForChanges();
    }
  }, [ip, Records]);

  const selectedOptionToUperCase = (text: any) => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  };

  const handleSelect = (target: DomainTarget) => {
    setSelectedOption(target);
    const domainTargetToIp: Record<DomainTarget, string> = {
      [DomainTarget.MAIN]: "185.123.53.227",
      [DomainTarget.KEITARO]: "46.246.96.114",
      [DomainTarget.HOSTING]: "46.246.98.201",
      [DomainTarget.UNKNOWN]: "none",
      [DomainTarget.OTHER]: "unknown",
    };
    const selectedTarget = domainTargetToIp[target];

    if (selectedTarget !== "unknown") {
      setIP({
        IP: domainTargetToIp[target],
        target: getDomainTargetByIp(domainTargetToIp[target]),
      });
    } else {
      setIP({
        IP: "",
        target: getDomainTargetByIp(domainTargetToIp[target]),
      });
    }
  };


  const DropDownElement = (
  <Dropdown
    className="custom_piker"
    title={selectedOptionToUperCase(selectedOption) || "Ціль"}
  >
    {Object.values(DomainTarget)
      .filter(
        (option) =>
          option !== DomainTarget.UNKNOWN 
      )
      .map((option) => (
        <Dropdown.Item
          key={option}
          // Uncomment and implement onSelect if needed
          onSelect={() => handleSelect(option)}
        >
          {option.charAt(0).toUpperCase() + option.slice(1)}
        </Dropdown.Item>
      ))}
  </Dropdown>
);


  let ipElement = (
    <div className="input_picker_group">
    <InputGroup size="lg" className="custom_input_host" >
      <AutoComplete
        value={ip.IP}
        onChange={handleIpChange}
        disabled={selectedOption !== "other"}
        data={[]}
        size="lg"
        style={{
          outline: ipErr ? `solid 1px red` : `none`,
        }}
      />
      {domainData?.target === DomainTarget.HOSTING && (
        <InputGroup.Button
          tabIndex={-1}
          appearance="primary"
          color="blue"
          onClick={onStaticModal}
          disabled={load}
        >
          {!load && <FileUploadIcon />}
          {load && <Loader />}
        </InputGroup.Button>
      )}
     
    </InputGroup>
    {domainData?.target !== DomainTarget.HOSTING && (
       <>
        {DropDownElement}
       </>
      )}
    </div>
  );

  const prohibitionToClose = () => {
    if (!isFormChanged) {
      onEditingModal();
    }
  };

  const handleSave = () => {
    if (isFormChanged) {
      const txtRecords = Array.from(Records.entries())
        .filter(([_, value]) => !value.deleted && value.type === "TXT")
        .map(([_, value]) => value.value);

      const mxRecords = Array.from(Records.entries())
        .filter(([_, value]) => !value.deleted && value.type === "MX")
        .map(([_, value]) => value.value);

      let recordsBody: Partial<IDomain> = {
        ...domainData,
        ip: ip.IP,
        txtRecords: txtRecords,
        mxRecord: mxRecords[0],
      };

      // Перевірка наявності значення для MX-запису
      if (mxRecords[0] !== undefined) {
        recordsBody.mxRecord = mxRecords[0];
      }
      console.log("Prepared records body:", recordsBody);

      PanelService.editDomain(recordsBody)
        .then((res: any) => {
          console.log("Server response:", res);
        })
        .catch((error: any) => {
          console.log("Error during saving:", error);
        });
      setIsFormChanged(false);
      onDomainDataUpdate(recordsBody);
      onEditingModal()
    }
  };

  const handleAbolition = () => {
    setIsFormChanged(!isFormChanged);
  };

  const handleAddTxtRecord = (value: string) => {
    const newRecord = { rows: 1, deleted: false, type: value };
    Records.set(uuidv4(), newRecord);
    setRecords(new Map(Records));
    setIsFormChanged(true);
    setSelectedOption(value);
  };

  const handleDelete = () => {
    setRecords((prevRecords) => {
      const updatedRecords = new Map(prevRecords);
      updatedRecords.set(deleteKey, {
        ...updatedRecords.get(deleteKey),
        deleted: true,
      });
      return updatedRecords;
    });
    setDeleteModal(!deleteModal);
    setIsFormChanged(true);
  };

  const confimDelete = (recordKey: string) => {
    setDeleteKey(recordKey);
    setDeleteModal(!deleteModal);
  };
  console.log(Records);

  const handleProxyChange = (e:any) => {
    setDomainData((prevData) => {
      if (!prevData) return null;
  
      const updatedData = JSON.parse(JSON.stringify(prevData));
      updatedData.proxied = !prevData.proxied;
      return updatedData;
    });
    setIsFormChanged(true)
  };
  
  
  return (
    <div className="editing_records_modal">
      <Modal
        backdrop="static"
        role="alertdialog"
        open={deleteModal}
        // onClose={handleClose}
        size="xs"
      >
        <Modal.Body>
          {/* <RemindIcon style={{ color: "#ffb300", fontSize: 24 }} /> */}
          <p className="confim_text_delete">
            Після підтвердження запис буде остаточно видалений. Цю дію не можна
            скасувати. Ви впевнені, що хочете продовжити?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary" color="red">
            Підтвердити
          </Button>
          <Button onClick={() => setDeleteModal(false)} appearance="subtle">
            Відміна
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop={"static"}
        size="sm"
        open={true}
        onClose={() => {
          if (!isFormChanged) {
            onEditingModal();
          }
        }}
      >
        <Modal.Header>
          <Modal.Title>
            {`Налаштування :`}
            <p className="title" onClick={()=>{window.open(`https://${domainData?.name}`, '_blank')}}>{domainData?.name}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Stack
              className="content"
              spacing={7}
              direction="column"
              alignItems="stretch"
            >
              <Form.ControlLabel>IP</Form.ControlLabel>
              {ipElement}
              {Array.from(Records.entries()).map(
                ([recordKey, recordValue], index) =>
                  !recordValue.deleted && (
                    <div key={recordKey} className="txt_flex_container">
                      <div className="input_label_wrap">
                        <span className="txt_label">
                          {recordValue.type === "TXT"
                            ? `txt[${index}]`
                            : `mx[${index}]`}
                        </span>
                        <div className="laber_delete_wrap">
                          {recordValue.type === "TXT" ? (
                            <textarea
                              rows={recordValue.rows}
                              placeholder="TXT Record"
                              onChange={(e) =>
                                handleTextareaChange(recordKey, e)
                              }
                              onKeyDown={(e) =>
                                handleTextareaKeyDown(recordKey, e)
                              }
                              className="ip_input text_area"
                              style={{
                                borderColor: recordValue.deleted
                                  ? "red"
                                  : "#3c3f43",
                              }}
                              defaultValue={recordValue.value}
                            />
                          ) : (
                            <textarea
                              placeholder="MX Record"
                              rows={recordValue.rows}
                              onChange={(e) =>
                                handleTextareaChange(recordKey, e)
                              }
                              onKeyDown={(e) =>
                                handleTextareaKeyDown(recordKey, e)
                              }
                              className="ip_input text_area"
                              style={{
                                borderColor: recordValue.deleted
                                  ? "red"
                                  : "#3c3f43",
                              }}
                              defaultValue={recordValue.value}
                            />
                          )}
                          <Button
                            appearance="primary"
                            className="delete_btn"
                            onClick={() => confimDelete(recordKey)}
                          >
                            <TrashIcon className="trash_icon" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </Stack>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="add_btn_container">
            <Dropdown title={"Додати TXT/MX запис"}>
              {recordsValues.map((option: string) => (
                <Dropdown.Item
                  key={option}
                  onSelect={() => handleAddTxtRecord(option)}
                >
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown>
            <div className="proxy_container" onClick={handleProxyChange}>
          {!domainData?.proxied && <img  className="proxy_togle"src="/icons/cloudflare.png"/>}
          {domainData?.proxied && <img className="proxy_togle" src="/icons/cloudflare-active.png"/>}
      </div>
          </div>

          <Button
            appearance="subtle"
            onClick={()=>{
              handleAbolition()
              setIP({
                IP:editingDomainData?.ip || "",
                target:getDomainTargetByIp(editingDomainData?.ip || "other")
              })
              setSelectedOption(getDomainTargetByIp(editingDomainData?.ip || "other"))
            }
              
            }
            disabled={!isFormChanged}
          >
            Відміна
          </Button>
          <Button
            appearance="primary"
            disabled={!isFormChanged}
            onClick={handleSave}
            className="save_button"
          >
            {"Зберегти"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
