import { useEffect, useState } from "react";
import { Dropdown } from "rsuite";

import {
  Badge,
  Button,
  Input,
  Message,
  Modal,
  Stack,
  useToaster,
} from "rsuite";
import RemindRoundIcon from "@rsuite/icons/RemindRound";
import {
  getDomainTargetByIp,
  IDomainsBuyRequestResponse,
  INamecheapBuyResult,
} from "@bprotsyk/aso-core";
import "./_buy-domains.scss";
import { DomainTarget } from "@bprotsyk/aso-core";
import { set } from "react-hook-form";
import { PanelService } from "network/panel/network";

interface IProps {
  onClose: () => void;
}

const notValidIp = "Невалідний або пустий IP";
const notValidDomain = "Неправильні дані";
const reloadMessage =
  "Додавання в процесі. Домени додадуться, але ви не зможете побачити результат операції!";
const domainPlaceHolder =
  "Вкажіть домени без схеми (просто domain.com), розділені комою, пробілом або новими рядками";
const placement = "topEnd";
const messageText = "Запит не вдався";
const messageType = "error";
const LOCAL_DOMAINS_STORAGE = "local_domains";

interface IProps {
  balance: number;
  onClose: () => void;
}
interface IP {
  IP: string;
  target: DomainTarget | string;
}

export default function BuyDomainModal({
  balance,
  onClose,
}: IProps) {
  const [domains, setDomains] = useState<string[]>([]);
  const [ip, setIP] = useState<IP>({
    IP: "",
    target: "other",
  });
  const [ipErr, setIpErr] = useState<boolean | string>(false);
  const [domainErr, setDomainErr] = useState<boolean | string>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [rows, setRows] = useState(5);
  //TODO IDomainsBuyRequestResponse add totalPrice
  const [result, setResult] = useState<IDomainsBuyRequestResponse | any>(null);
  const [nextStep, setNextStep] = useState<string>("first");
  const [activeDomain, setActiveDomain] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [butonDisable, setButonDisable] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] = useState<DomainTarget>(
    DomainTarget.OTHER
  );
  const [domainSetupResult, setDomainSetupResult] =
    useState<INamecheapBuyResult>();
  const toaster = useToaster();

  const domainPattern =
    /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)*[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
  const ipPattern =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const calculateRowsForText = (text: string) => {
    // Заміна всіх переходів на новий рядок та табуляцій на пробіли
    const normalizedText = text.replace(/\s+/g, " ").trim();

    // Якщо текст порожній після нормалізації, повертаємо 1 рядок
    if (!normalizedText) {
      return 1;
    }

    // Максимальна кількість символів в одному рядку
    const charsPerLine = 70;

    // Підрахунок кількості рядків з урахуванням символів переведення рядків (\n)
    const lines = normalizedText.split("\n").reduce((acc, line) => {
      return acc + Math.ceil(line.length / charsPerLine);
    }, 0);

    // Повертаємо кількість рядків або мінімум 1
    setRows(Math.max(lines + 1, 5));
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value: string = e.target.value;
    calculateRowsForText(value);

    // Розділяємо текст на домени, але зберігаємо коми та пробіли
    const domainArr: string[] = value
      .split(/[,\s\n]+/)
      .map((domain) => domain.trim().replace(/\s+/g, ",").replace(/,,+/g, ","));

    // Перевіряємо кожен домен окремо
    if (domainArr.length > 0) {
      const filteredDomainArr = domainArr.filter(
        (domain) => domain.trim() !== ""
      );

      const invalidDomains = filteredDomainArr.filter(
        (domain) => !domainPattern.test(domain)
      );
      console.log(domainArr.length);

      if (invalidDomains.length > 0 && invalidDomains[0] !== "") {
        setDomainErr(`Некоректні домени: ${invalidDomains.join(", ")}`);
      } else {
        setDomainErr(false);
      }

      // Зберігаємо масив доменів у Local Storage
      setDomains(domainArr);
      localStorage.setItem(LOCAL_DOMAINS_STORAGE, JSON.stringify(domainArr));
    }
  };

  useEffect(() => {
    if (domains) {
      calculateRowsForText(JSON.stringify(domains));
    }
  });

  const handleIpChange = (handleIP: string) => {
    if (ip.target === "other") {
      setIP({
        IP: handleIP,
        target: getDomainTargetByIp(handleIP) || "unknown",
      });
      if (!ip.target || (handleIP.length > 0 && !ipPattern.test(handleIP))) {
        setIpErr(notValidIp);
      } else {
        setIpErr(false);
      }
    }
  };
  useEffect(() => {
    if (ip.IP && ip.target) {
      setIpErr(false);
    }
  }, [ip]);

  const handleSelect = (target: DomainTarget) => {
    setSelectedOption(target);
    const domainTargetToIp: Record<DomainTarget, string> = {
      [DomainTarget.MAIN]: "185.123.53.227",
      [DomainTarget.KEITARO]: "46.246.96.114",
      [DomainTarget.HOSTING]: "46.246.98.201",
      [DomainTarget.UNKNOWN]: "none",
      [DomainTarget.OTHER]: "unknown",
    };
    const selectedTarget = domainTargetToIp[target];

    if (selectedTarget !== "unknown") {
      setIP({
        IP: domainTargetToIp[target],
        target: getDomainTargetByIp(domainTargetToIp[target]),
      });
    } else {
      setIP({
        IP: "",
        target: getDomainTargetByIp(domainTargetToIp[target]),
      });
    }
  };

  useEffect(() => {
    if (ip.IP.length > 0 && !ipPattern.test(ip.IP)) {
      setIpErr(notValidIp);
    }
  }, [ip]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (toggle) {
        event.preventDefault();
        return reloadMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [toggle]);

  useEffect(() => {
    const localDomain = localStorage.getItem(LOCAL_DOMAINS_STORAGE);
    if (localDomain) {
      setDomains(JSON.parse(localDomain));
    }
  }, []);

  const capitalize = (word: string) =>
    word.charAt(0).toUpperCase() + word.slice(1);

  let ipElement = (
    <div className="customize_disable">
      <Input
        className={`input custom_ip_input ${ipErr ? "input_error" : ""}`}
        id="ip"
        placeholder="Введіть IP цілі"
        value={ip.target === "other" ? ip.IP : capitalize(ip.target)}
        onChange={(ip: string) => handleIpChange(ip)}
        disabled={ip.target !== "unknown" && ip.target !== "other"}
      />
    </div>
  );

  const closeModal = () => {
    if (!toggle) {
      onClose();
    }
  };

  const sendData = () => {
    setToggle(true);
    if (!domainErr && !ipErr && ip.IP.length > 0) {
      const sendDomains = domains.filter((domain) => domain !== "");

      const body = { domains: sendDomains, ip: ip.IP };
      setButonDisable(true);

      PanelService.getBuyDomain(body).then((res) => {
        if (res?.data) {
          setResult(res?.data);
          setNextStep("second");
        } else {
          toaster.push(message, { placement: placement, duration: 5000 });
          setButonDisable(false);
        }
        setToggle(false);

        const isActive = res?.data.info.some(
          (product: any) => parseFloat(product?.price) > 0
        );

        let totalPrice = 0;
        localStorage.removeItem(LOCAL_DOMAINS_STORAGE);

        res?.data.info.forEach((item: any) => {
          totalPrice += item.price;
        });
        setTotalPrice(totalPrice);

        if (isActive) {
          setActiveDomain(true);
        }
      });
    }
    if (ip.IP.length <= 0) {
      setIpErr("поле IP  обовязкове");
    }
  };

  const buyDomainRequest = () => {
    const { requestId } = result;

    if (activeDomain && balance && requestId) {
      PanelService.buyDomains(requestId).then((res: any) =>
        setDomainSetupResult(res?.data)
      );
      setToggle(true);
      setNextStep("final");
      localStorage.removeItem(LOCAL_DOMAINS_STORAGE);
    } else {
      toaster.push(message, { placement: placement, duration: 5000 });
    }
  };

  const message = (
    <Message showIcon type={messageType} closable color="black">
      <strong>{messageType}!</strong> {messageText}
    </Message>
  );

  const buyModalElement = (
    <Modal.Body>
      <div className="container">
        <Stack
          className="content"
          spacing={7}
          direction="column"
          alignItems="center"
        >
          <div className="domain_wrap">
            <div className="text_area_custom_wrap">
              <textarea
                rows={rows}
                value={domains}
                placeholder={domainPlaceHolder}
                onChange={(e) => handleTextareaChange(e)}
                className={` ip_input ${domainErr ? "input_error" : ""}`}
              />
              {domainErr && (
                <div className="hover_error">
                  <RemindRoundIcon className="error_icon" />
                  <span className="err_message">{domainErr}</span>
                </div>
              )}
            </div>
            <div className="input_custom_wrap">
              {ipElement}
              {ipErr && (
                <div className="hover_error">
                  <RemindRoundIcon className="error_icon" />
                  <span className="err_message">{ipErr}</span>
                </div>
              )}
            </div>
          </div>
        </Stack>
      </div>
    </Modal.Body>
  );

  const CustomDropdown = ({ ...props }) => (
    <Dropdown {...props}>
      {Object.values(DomainTarget).map((option) => (
        <Dropdown.Item key={option}>{option}</Dropdown.Item>
      ))}
    </Dropdown>
  );

  const nextStepModalContent = (
    <div className="domain_list_container">
      <p className="total_price">
        Загальна ціна :
        <span className="totalPrice_price">${totalPrice.toFixed(2)}</span>
      </p>
      <div className="list_domain_wrap">
        {result?.info.some((product: any) => product.price > 0) && (
          <>
            <p className="title">Доступно :</p>
            <ul className="list">
              {result?.info?.map((product: any) => {
                if (product.price <= 0) return null;
                return (
                  <li key={product.name} className="list_item">
                    <Badge className="active_domain_badge" />
                    <span className="name"> {product.name} : </span>
                    <span className="price">{product.price.toFixed(2)}$</span>
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {result?.info.some((product: any) => product.price <= 0) && (
          <div>
            <p className="title">Недоступно</p>
            <ul className="list">
              {result?.info?.map((product: any) => {
                if (product.price > 0) return null;
                return (
                  <li key={product.name} className="list_item">
                    <Badge className="inactive_domain_badge" />
                    {product.name}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );

  useEffect(() => {
    if (!domainErr && !ipErr && ip.IP.length > 5 && ip.target) {
      setButonDisable(false);
    } else setButonDisable(true);
  }, [domainErr, ipErr, domainErr, domains, ip]);

  const sucsesBuy = (
    <div className="domain_list_container">
      <p className="title_bot">
        Про налаштування куплених доменів буде сповіщено в бота
      </p>
      {domainSetupResult?.succeed.length && (
        <div className="list_wrap">
          <p className="title">Успішні домени :</p>
          <ul className="list">
            {Object.entries(domainSetupResult.succeed).map(
              ([itemName, itemInfo]) => (
                <li key={itemName} className="list_item ancor_list_item">
                  <Badge className="active_domain_badge" />
                  <p className="ancors">
                    {itemName}: {itemInfo as any}
                  </p>
                </li>
              )
            )}
          </ul>
          {domainSetupResult?.failed.length && (
            <>
              <p className="title">Не успішні домени:</p>
              <ul className="list">
                {Object.entries(domainSetupResult.failed).map(
                  ([itemName, failReason]) => (
                    <li key={itemName} className="list_item  ancor_list_item">
                      <Badge className="inactive_domain_badge" />
                      {itemName}: {failReason as any}
                    </li>
                  )
                )}
              </ul>
            </>
          )}
          <p className="total">
            Всього оплачено :{" "}
            <span className="price">{domainSetupResult.paidTotal}$</span>
          </p>
        </div>
      )}
      <Button onClick={() => onClose()} color="green" appearance="primary">
        Гаразд
      </Button>
    </div>
  );
    const selectedOptionToUperCase = (text: any) => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  };

  return (
    <>
      <Modal
        backdrop={false}
        size="sm"
        open={true}
        onClose={closeModal}
        // overflow={true}
      >
        {!toggle && (
          <Modal.Header>
            <Modal.Title>{`Купівля доменів`}</Modal.Title>
          </Modal.Header>
        )}
        {nextStep === "first" && <div>{buyModalElement}</div>}
        {nextStep === "second" && <div>{nextStepModalContent}</div>}
        {nextStep === "final" && <div>{sucsesBuy}</div>}

        <Modal.Footer>
          <div className="add_btn_wrap_buy">
            {nextStep === "first" && (
              <div className="button_wrap_buy_domain">
                <Dropdown
                  title={selectedOptionToUperCase(selectedOption) || "Ціль"}
                >
                  {Object.values(DomainTarget).map((option) => (
                    <Dropdown.Item
                      key={option}
                      onSelect={() => handleSelect(option)}
                    >
                      {option?.charAt(0).toUpperCase() + option.slice(1)}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
                <Button
                  onClick={sendData}
                  disabled={butonDisable}
                  color="green"
                  appearance="primary"
                >
                  Купити домени
                </Button>
              </div>
            )}
            {nextStep === "second" && (
              <div className="button_wrap">
                <Button
                  onClick={() => {
                    setNextStep("first");
                    setButonDisable(false);
                  }}
                  appearance="subtle"
                >
                  Назад{" "}
                </Button>
                <Button
                  onClick={buyDomainRequest}
                  color="green"
                  appearance="primary"
                  disabled={!activeDomain}
                >
                  Купити{" "}
                </Button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

