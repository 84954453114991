import { useEffect, useRef, useState } from "react";
import { Table, Badge, Checkbox } from "rsuite";
import { PanelService } from "../../../../network/panel/network";
import { IDomain, DomainStatus } from "@bprotsyk/aso-core";
import React from "react";
import InfoRoundIcon from "@rsuite/icons/InfoRound";
import TimeIcon from "@rsuite/icons/Time";
import RemindRoundIcon from "@rsuite/icons/RemindRound";

import ReloadIcon from "@rsuite/icons/Reload";
import "./_table.scss";

const LOCAL_STORAGE_ARCHIVED_DOMAIN = "domains-for-archived";

const { Column, HeaderCell, Cell } = Table;

type DomainSortKeys = keyof IDomain | "";
type SortType = "asc" | "desc" | undefined;
interface FilterState {
  status: string[];
  archived: string[];
  target: string[];
}

interface IHandleEditingDomain {
  onHandleEditingDomain: (e: any) => void;
  searchQuery: string;
  filterSelectors: FilterState;
  onToggleButtonShow: (e: boolean) => void;
  tableData: IDomain[];
}

interface IDomainsArchived {
  id: string;
  name: string;
}

// status color
const getStatusIndicator = (status: DomainStatus) => {
  switch (status) {
    case DomainStatus.PENDING:
      return "#ffeb3b";
    case DomainStatus.READY:
    case DomainStatus.READY_AND_NOTIFIED:
      return "#4caf50";
    default:
      return "#9e9e9e";
  }
};
const NOTE_COLOR = "#ffc73d";
const RELOAD_COLOR = "#34c3ff";

const ContainerTable = React.memo(
  ({
    onHandleEditingDomain,
    searchQuery,
    filterSelectors,
    onToggleButtonShow,
    tableData,
  }: IHandleEditingDomain) => {

    
    const [sortColumn, setSortColumn] = useState<DomainSortKeys>("");
    const [data, setData] = useState<IDomain[]>([]);
    const [sortType, setSortType] = useState<SortType>(undefined);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [checkableMap, setCheckableMap] = useState<IDomainsArchived[]>([]);
    const [isVisible, setIsVisible] = useState(false);
    const [hostColumnWidth, setHostColumnWidth] = useState<number>();
    const [remainingColumnWidth, setRemainingColumnWidth] = useState<number>(1);
    const [tableSize, setTableSize] = useState(window.innerWidth - 20);
    const [hidenColum, setHidenColum] = useState(
      tableSize < 450 ? false : true
    );

    useEffect(()=>{
      setData(tableData);
    },[tableData])
  


    // custom date display
    const timestampToDaysWithHandlingOneDay = (milliseconds: number) => {
      const days = Math.round(milliseconds / (1000 * 60 * 60 * 24));
      const mobile = window.innerWidth > 480 ? false : true;
      if (mobile) {
        if (days % 10 > 1 && days % 10 < 5) {
          return days;
        } else {
          return <RemindRoundIcon className="expired_icon" />;
        }
      }
      if (days === 0) {
        return `Сьогодні`;
      } else if (days % 10 == 1) {
        return `1 день`;
      } else if (days % 10 > 1 && days % 10 < 5) {
        return `${days} дні`;
      } else if (days < 0) {
        return <RemindRoundIcon className="expired_icon" />;
      } else {
        return `${days} днів`;
      }
    };

    // table sort
    const getData = () => {
      // Фільтрація даних за фільтрами
      let filteredData = data; // Використовуємо копію data

      if (
        filterSelectors?.status?.length > 0 ||
        filterSelectors?.archived?.length > 0 ||
        filterSelectors?.target?.length > 0
      ) {
        filteredData = data.filter((item) => {
          const statusMatch =
            filterSelectors?.status?.length > 0
              ? filterSelectors?.status.includes(item.status)
              : true;

          const archivedMatch =
            filterSelectors?.archived?.length > 0
              ? filterSelectors?.archived.includes(
                  item.archived ? "archived" : "notArchived"
                )
              : true;

          const targetMatch =
            filterSelectors.target?.length > 0
              ? filterSelectors.target.includes(item.target)
              : true;

          return statusMatch && archivedMatch && targetMatch;
        });
      }

      if (!sortColumn || !sortType) {
        return filteredData;
      }

      return [...filteredData]?.sort((a, b) => {
        let x: any = a[sortColumn as keyof IDomain];
        let y: any = b[sortColumn as keyof IDomain];

        if (sortColumn === "createdAt" || sortColumn === "expiresAt") {
          x = new Date(a[sortColumn]);
          y = new Date(b[sortColumn]);
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    };

    const filterData = () => {
      if (!searchQuery || searchQuery.length === 0) {
        return getData();
      }

      return data?.filter((domain) => {
        return (
          domain?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          domain?.ip?.toLowerCase()?.includes(searchQuery.toLowerCase())
        );
      });
    };

    const handleSortColumn = (sortColumn: any, sortType: any) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setSortColumn(sortColumn);
        setSortType(sortType);
      }, 900);
    };

    const checkboxChanges = (domain: any): void => {
      setCheckableMap((prevDomains) => {
        const domainExists = prevDomains.some((d) => d.id === domain.id);

        let updatedDomains;
        if (domainExists) {
          updatedDomains = prevDomains.filter((d) => d.id !== domain.id);
          sessionStorage.setItem(
            LOCAL_STORAGE_ARCHIVED_DOMAIN,
            JSON.stringify(updatedDomains)
          );
        } else {
          updatedDomains = [
            ...prevDomains,
            { id: domain.id, name: domain.name },
          ];
          sessionStorage.setItem(
            LOCAL_STORAGE_ARCHIVED_DOMAIN,
            JSON.stringify(updatedDomains)
          );
        }

        setChecked(updatedDomains.length > 0);
        return updatedDomains;
      });
    };

    useEffect(() => {
      onToggleButtonShow(checked);
    }, [checked]);

    const handleMouseOver = () => {
      setIsVisible(true);
    };

    const handleMouseOut = () => {
      setIsVisible(false);
    };

    const isSortable = window.innerWidth > 900 ? true : false;

    window.addEventListener("resize", function () {
      setTableSize(window.innerWidth);
    });
    useEffect(() => {
      setHidenColum(tableSize < 450 ? false : true);
    }, [tableSize]);

    const calculateColumnSizes = (tableWidth: number, totalColumns: number) => {
      const hostColumnRatio = 0.4; // Хост займає 40% ширини
      const remainingRatio = (1 - hostColumnRatio) / (totalColumns - 1); // Решта колонок отримує рівну частку

      const hostColumnWidth = tableWidth * hostColumnRatio;
      const remainingColumnWidth = tableWidth * remainingRatio;

      return {
        hostColumnWidth,
        remainingColumnWidth,
      };
    };

    useEffect(() => {
      const updateColumnSizes = () => {
        const { hostColumnWidth, remainingColumnWidth } = calculateColumnSizes(
          window.innerWidth - 20, // Враховуємо padding
          hidenColum ? 7 : 5 // Загальна кількість колонок
        );
        setHostColumnWidth(hostColumnWidth);
        setRemainingColumnWidth(remainingColumnWidth);
      };

      updateColumnSizes(); // Ініціалізація
      window.addEventListener("resize", updateColumnSizes);

      return () => {
        window.removeEventListener("resize", updateColumnSizes);
      };
    }, []);

    return (
      <div>
        {isVisible && (
          <div className="note_modal">
            <p className="text">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Laudantium obcaecati incidunt delectus doloremque cumque commodi
              natus rerum, soluta sint, unde, fuga consequatur. Blanditiis nobis
              minima neque accusantium ipsa praesentium ad.
            </p>
            <span className="sub_text">
              редагування приміток доступне в налаштуваннях хоста
            </span>
          </div>
        )}

        {data && (
          <Table
            data={filterData()}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            loading={loading}
            width={tableSize}
            className="table"
          >
            <Column width={remainingColumnWidth}>
              <HeaderCell>
                <Checkbox
                  color="blue"
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                    if (checkableMap.length > 0) {
                      setCheckableMap([]);
                    } else {
                      setCheckableMap(() => {
                        return filterData()?.map((domain) => ({
                          id: domain.id,
                          name: domain.name,
                        }));
                      });
                    }
                  }}
                ></Checkbox>
              </HeaderCell>
              <Cell dataKey="arhived">
                {(rowData) => {
                  return (
                    <div className="arhived_custom_container">
                      <Checkbox
                        color="blue"
                        onChange={() => checkboxChanges(rowData)}
                        checked={
                          checkableMap.find(
                            (domain) => domain.id === rowData.id
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                  );
                }}
              </Cell>
            </Column>

            {hidenColum && (
              <Column
                align="center"
                fixed
                sortable={isSortable}
                width={remainingColumnWidth}
              >
                <HeaderCell>Статус</HeaderCell>
                <Cell dataKey="status">
                  {(rowData) => (
                    <Badge
                      style={{
                        background: getStatusIndicator(rowData.status),
                      }}
                    />
                  )}
                </Cell>
              </Column>
            )}
            {/* {hidenColum && (
              <Column align="left" fixed sortable={isSortable} width={remainingColumnWidth}>
                <HeaderCell>Назва</HeaderCell>
                <Cell dataKey="name">
                  {(rowData) => {
                    return <p></p>;
                  }}
                </Cell>
              </Column>
            )} */}

            <Column width={hostColumnWidth} fixed sortable={isSortable}>
              <HeaderCell>Хост</HeaderCell>
              <Cell dataKey="name" className="host-cell">
                {(rowData) => (
                  <div className="host-cel">
                    <p className="text">{rowData.name}</p>
                  </div>
                )}
              </Cell>
            </Column>

            <Column width={remainingColumnWidth} sortable={isSortable}>
              <HeaderCell>
                <>
                  {hidenColum && "Зпливає"}
                  {!hidenColum && <TimeIcon className="time_icon" />}
                </>
              </HeaderCell>
              <Cell dataKey="expiresAt">
                {(rowData) => (
                  <div className="date-cell">
                    {timestampToDaysWithHandlingOneDay(
                      rowData.expiresAt - Date.now()
                    )}
                  </div>
                )}
              </Cell>
            </Column>

            <Column width={remainingColumnWidth} sortable={isSortable}>
              <HeaderCell>A @</HeaderCell>
              <Cell dataKey="ip" className="ip-cell">
                {(rowData) => (
                  <p
                    className="custom_text"
                    onClick={() => onHandleEditingDomain(rowData)}
                  >
                    {rowData.ip}
                  </p>
                )}
              </Cell>
            </Column>

            {hidenColum && (
              <Column width={remainingColumnWidth} sortable={false}>
                <HeaderCell>
                  <>
                    {tableSize > 900 && "Авто-оновлення"}

                    {tableSize <= 900 && (
                      <div>
                        <ReloadIcon
                          fill={RELOAD_COLOR}
                          className="reload_icon"
                        />
                      </div>
                    )}
                  </>
                </HeaderCell>
                <Cell dataKey="auto-reload">
                  {(rowData: IDomain) => {
                    if (rowData) {
                      return (
                        <div>
                          {
                            <ReloadIcon
                              fill={RELOAD_COLOR}
                              className="reload_icon"
                            />
                          }
                        </div>
                      );
                    }
                  }}
                </Cell>
              </Column>
            )}

            {hidenColum && (
              <Column width={remainingColumnWidth} sortable={isSortable}>
                <HeaderCell>примітки</HeaderCell>
                <Cell dataKey="note">
                  {(rowData: IDomain) => {
                    // if (rowData?.note) {
                    return (
                      <div
                        className="note_icon"
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                      >
                        {
                          <InfoRoundIcon
                            fill={NOTE_COLOR}
                            className="note_icon"
                          />
                        }
                      </div>
                    );
                    // }
                  }}
                </Cell>
              </Column>
            )}

            {!hidenColum && (
              <Column
                align="center"
                fixed
                sortable={isSortable}
                width={remainingColumnWidth}
              >
                <HeaderCell>
                  <Badge
                    style={{
                      background: "#34c3ff",
                    }}
                  />
                </HeaderCell>
                <Cell dataKey="status">
                  {(rowData) => (
                    <Badge
                      style={{
                        background: getStatusIndicator(rowData.status),
                      }}
                    />
                  )}
                </Cell>
              </Column>
            )}
          </Table>
        )}
      </div>
    );
  }
);

export default ContainerTable;
